import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/ploi/front.preprod.dadoun.co/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/ploi/front.preprod.dadoun.co/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/ploi/front.preprod.dadoun.co/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_yYGenSyh7xG6UX12MtHn1ttwCYYlO60EzzpRSKi6vJU from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_qFoR2KwrfPbeYF1NbvQtNji8ub5JyA6jqzexZ5yyjV8 from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0 from "/home/ploi/front.preprod.dadoun.co/.nuxt/primevue-plugin.mjs";
import plugin_client_wp4kxkdwIclITZVcWkXPniTgFVTtTwZamTFNo05D70w from "/home/ploi/front.preprod.dadoun.co/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/home/ploi/front.preprod.dadoun.co/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_JqAW0L_Vf8VnAERtT9L08Cs5C9y063hXVGrpEiIZAmY from "/home/ploi/front.preprod.dadoun.co/modules/nuxt-permissions/src/runtime/plugin.ts";
import plugin_fRaQx5M_8jZqILNhfX5mJV2jTDZR8pN5y_8LEKYG9qU from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt-multi-tenancy/dist/runtime/plugin.mjs";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/home/ploi/front.preprod.dadoun.co/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import eventBus_HPNdAg_I8qVFEPDizPI_AFfsut3TFiqAaFEcG1Ahby0 from "/home/ploi/front.preprod.dadoun.co/plugins/eventBus.ts";
import laravel_echo_3vL65RVqIEotB8fg8vveKKKidiIcuEunMC7BqfXhzRY from "/home/ploi/front.preprod.dadoun.co/plugins/laravel-echo.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_yYGenSyh7xG6UX12MtHn1ttwCYYlO60EzzpRSKi6vJU,
  plugin_qFoR2KwrfPbeYF1NbvQtNji8ub5JyA6jqzexZ5yyjV8,
  primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0,
  plugin_client_wp4kxkdwIclITZVcWkXPniTgFVTtTwZamTFNo05D70w,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_JqAW0L_Vf8VnAERtT9L08Cs5C9y063hXVGrpEiIZAmY,
  plugin_fRaQx5M_8jZqILNhfX5mJV2jTDZR8pN5y_8LEKYG9qU,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  eventBus_HPNdAg_I8qVFEPDizPI_AFfsut3TFiqAaFEcG1Ahby0,
  laravel_echo_3vL65RVqIEotB8fg8vveKKKidiIcuEunMC7BqfXhzRY
]