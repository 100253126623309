
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93RpWnt4ncd3z0bkgmipxUK6uKvFIipXeGqIG5Zz5_455rQMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/[id].vue?macro=true";
import { default as indexquOM5Bo7DhNMWMKlZwqRIY3gRgJTkEJxi6wIyTtKrZsMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/index.vue?macro=true";
import { default as _91id_93aRFs4_ePwKO254pERqpDngqmk7Ynjnpg_FDp6RUUd5wMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/events/[id].vue?macro=true";
import { default as indexIwpgbnFGcP8dfUIagYtOR33G0_459j8aYwt501xwFRdjAMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/index.vue?macro=true";
import { default as create_45editwsl8V4H_Dm4AxddCqF5s_45MwN_haDlaiFFNhzroFaxfsMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/create-edit.vue?macro=true";
import { default as indexlvMKvA6JWrV6GYFEB4eQhGOCVAN1bpt9EtL7DFWF4PIMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/index.vue?macro=true";
import { default as noactive_45invoicesBWHmY8VazjVNJeCk6ttuGTV6SdREjOBciNnAS372yF4Meta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/noactive-invoices.vue?macro=true";
import { default as _91id_93qBp86Bs_As7mxsWyRvWa32xpwOz7ZQDde48_45_2nsV0cMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/show/[id].vue?macro=true";
import { default as _91id_93U5Rf52Z7EiQ7JjDGvbhP70NDSmD_NMCdGdTMRBxhYygMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/[id].vue?macro=true";
import { default as indexexRb5Tx8F_0S6yqo7ea22z5x21QxFS7rAS3_4551lrXE8Meta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/index.vue?macro=true";
import { default as _91id_93QdGxgmcJIVSKK7mcE7R6MLVoPgFvgiDzflWwLYkYjwEMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/materials/[id].vue?macro=true";
import { default as indexlGR4NXLJZdTOL6_45pBj_3yR03jci0cHlC1a_KWewEPlsMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/materials/index.vue?macro=true";
import { default as _91id_93k8Uqdw7JZ8syppbDOHdqGyf3pfAD7JeKnDOIXJ5uDfAMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/[id].vue?macro=true";
import { default as indexO5tj5mUWQat4305q_45_45h8V38Ri_sWXcQRkNRZ84GstWIMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/index.vue?macro=true";
import { default as _91id_93OoKbRilsUc7Nl6duRisPm3MOSl7WtDJ0Bk4kOwt687MMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/payments/[id].vue?macro=true";
import { default as indexphoIEGHbuceD_45U1C_YWS_45f9O6_45swV_3bzVABDNdTwBQMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/payments/index.vue?macro=true";
import { default as index0lHU40_6zLQ5o4VoKbOOgrZBeY8JKn00VfW6HJ6l3OEMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/placers/index.vue?macro=true";
import { default as _91id_93YHCnOZKiw60IhTOiLNwOGZyk_45suCDZoFwTM6ZK6Dj9AMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/placers/show/[id].vue?macro=true";
import { default as index2tmfoEAWUTPL_45QIjTFPBxeExS8ucTCMdRHEHEf_OA6sMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/renewals/index.vue?macro=true";
import { default as indexKGQHY5c2s5iQcZ8teRxyznvdh3vhbZzvdo5R2Sv_wUYMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/index.vue?macro=true";
import { default as security_KS7PzEboRQrRMNtNdMmmCZzpeDDvIOWhmBeE3__45ex4Meta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/security.vue?macro=true";
import { default as create_45editE8TfJk6Vtheg4_45ap6J12_cERlSa_45iVEnoH4i6VsFXlAMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/create-edit.vue?macro=true";
import { default as indexQhOAmctKd8lRtoT6NrMx_45wLCmMHQ_45wCzhov7D2T2Z8YMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/index.vue?macro=true";
import { default as _91id_93heDcD8bWmpU6_4GsapQCfGfluhQ7spl38cf7RdubMLoMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/[id].vue?macro=true";
import { default as createXl33GZ8SOkob914L8HkTKSQeQzufwBB409W4FHIT2ikMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/create.vue?macro=true";
import { default as index2e9ZdDgsgMbeZjnFKVtrt6AwOqdCAv5tezehfoZkIwYMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/index.vue?macro=true";
import { default as _91id_93bNdg8G6TNIMLbZ56h_TYXLwblkK3QHuVfFSDIkp5ByQMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/show/[id].vue?macro=true";
import { default as forget_45password0F8SOPU2BlC0gNKR2Qy33k93_45ME2Gz96bMrY_ESmiREMeta } from "/home/ploi/front.preprod.dadoun.co/pages/forget-password.vue?macro=true";
import { default as indexTzqcTAs2QrZ_45CJUMyvFG8g2IdIj2YDlCeAK80F2MUCQMeta } from "/home/ploi/front.preprod.dadoun.co/pages/index.vue?macro=true";
import { default as loginKTCDB06rLuqFvDCxIjZ1_45r86J1_45W3YEUaJeqVl_45sd8IMeta } from "/home/ploi/front.preprod.dadoun.co/pages/login.vue?macro=true";
import { default as _91email_93A_45VCa3gWXDicbT6y1Zby8EEXKwdABMb5AleWUWnNuKMMeta } from "/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/[email].vue?macro=true";
import { default as indexVECjan_DLXytPMOCqH0FPKvTkTDYoq3WANEuLWYUnfcMeta } from "/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/index.vue?macro=true";
import { default as create_45editdu73qv_452gv4nSpZbdjsyl_7N2dt00qTcJSS9xBZW9CwMeta } from "~/pages/[tenant]/subscriptions/create-edit.vue?macro=true";
export default [
  {
    name: "tenant-companies-id",
    path: "/:tenant()/companies/:id()",
    meta: { ...(_91id_93RpWnt4ncd3z0bkgmipxUK6uKvFIipXeGqIG5Zz5_455rQMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/[id].vue")
  },
  {
    name: "tenant-companies",
    path: "/:tenant()/companies",
    meta: { ...(indexquOM5Bo7DhNMWMKlZwqRIY3gRgJTkEJxi6wIyTtKrZsMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/index.vue")
  },
  {
    name: "tenant-events-id",
    path: "/:tenant()/events/:id()",
    meta: { ...(_91id_93aRFs4_ePwKO254pERqpDngqmk7Ynjnpg_FDp6RUUd5wMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/events/[id].vue")
  },
  {
    name: "tenant",
    path: "/:tenant()",
    meta: { ...(indexIwpgbnFGcP8dfUIagYtOR33G0_459j8aYwt501xwFRdjAMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/index.vue")
  },
  {
    name: "tenant-invoices-create-edit",
    path: "/:tenant()/invoices/create-edit",
    meta: { ...(create_45editwsl8V4H_Dm4AxddCqF5s_45MwN_haDlaiFFNhzroFaxfsMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/create-edit.vue")
  },
  {
    name: "tenant-invoices",
    path: "/:tenant()/invoices",
    meta: { ...(indexlvMKvA6JWrV6GYFEB4eQhGOCVAN1bpt9EtL7DFWF4PIMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/index.vue")
  },
  {
    name: "tenant-invoices-noactive-invoices",
    path: "/:tenant()/invoices/noactive-invoices",
    meta: { ...(noactive_45invoicesBWHmY8VazjVNJeCk6ttuGTV6SdREjOBciNnAS372yF4Meta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/noactive-invoices.vue")
  },
  {
    name: "tenant-invoices-show-id",
    path: "/:tenant()/invoices/show/:id()",
    meta: { ...(_91id_93qBp86Bs_As7mxsWyRvWa32xpwOz7ZQDde48_45_2nsV0cMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/show/[id].vue")
  },
  {
    name: "tenant-markets-id",
    path: "/:tenant()/markets/:id()",
    meta: { ...(_91id_93U5Rf52Z7EiQ7JjDGvbhP70NDSmD_NMCdGdTMRBxhYygMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/[id].vue")
  },
  {
    name: "tenant-markets",
    path: "/:tenant()/markets",
    meta: { ...(indexexRb5Tx8F_0S6yqo7ea22z5x21QxFS7rAS3_4551lrXE8Meta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/index.vue")
  },
  {
    name: "tenant-materials-id",
    path: "/:tenant()/materials/:id()",
    meta: { ...(_91id_93QdGxgmcJIVSKK7mcE7R6MLVoPgFvgiDzflWwLYkYjwEMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/materials/[id].vue")
  },
  {
    name: "tenant-materials",
    path: "/:tenant()/materials",
    meta: { ...(indexlGR4NXLJZdTOL6_45pBj_3yR03jci0cHlC1a_KWewEPlsMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/materials/index.vue")
  },
  {
    name: "tenant-merchants-id",
    path: "/:tenant()/merchants/:id()",
    meta: { ...(_91id_93k8Uqdw7JZ8syppbDOHdqGyf3pfAD7JeKnDOIXJ5uDfAMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/[id].vue")
  },
  {
    name: "tenant-merchants",
    path: "/:tenant()/merchants",
    meta: { ...(indexO5tj5mUWQat4305q_45_45h8V38Ri_sWXcQRkNRZ84GstWIMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/index.vue")
  },
  {
    name: "tenant-payments-id",
    path: "/:tenant()/payments/:id()",
    meta: { ...(_91id_93OoKbRilsUc7Nl6duRisPm3MOSl7WtDJ0Bk4kOwt687MMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/payments/[id].vue")
  },
  {
    name: "tenant-payments",
    path: "/:tenant()/payments",
    meta: { ...(indexphoIEGHbuceD_45U1C_YWS_45f9O6_45swV_3bzVABDNdTwBQMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/payments/index.vue")
  },
  {
    name: "tenant-placers",
    path: "/:tenant()/placers",
    meta: { ...(index0lHU40_6zLQ5o4VoKbOOgrZBeY8JKn00VfW6HJ6l3OEMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/placers/index.vue")
  },
  {
    name: "tenant-placers-show-id",
    path: "/:tenant()/placers/show/:id()",
    meta: { ...(_91id_93YHCnOZKiw60IhTOiLNwOGZyk_45suCDZoFwTM6ZK6Dj9AMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/placers/show/[id].vue")
  },
  {
    name: "tenant-renewals",
    path: "/:tenant()/renewals",
    meta: { ...(index2tmfoEAWUTPL_45QIjTFPBxeExS8ucTCMdRHEHEf_OA6sMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/renewals/index.vue")
  },
  {
    name: "tenant-settings",
    path: "/:tenant()/settings",
    meta: { ...(indexKGQHY5c2s5iQcZ8teRxyznvdh3vhbZzvdo5R2Sv_wUYMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/index.vue")
  },
  {
    name: "tenant-settings-security",
    path: "/:tenant()/settings/security",
    meta: {"middleware":["nuxt-permissions","permissions","sanctum:auth"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/security.vue")
  },
  {
    name: "tenant-subscriptions-create-edit",
    path: "/:tenant()/subscriptions/create-edit",
    meta: { ...(create_45editE8TfJk6Vtheg4_45ap6J12_cERlSa_45iVEnoH4i6VsFXlAMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/create-edit.vue")
  },
  {
    name: "tenant-subscriptions",
    path: "/:tenant()/subscriptions",
    meta: { ...(indexQhOAmctKd8lRtoT6NrMx_45wLCmMHQ_45wCzhov7D2T2Z8YMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/index.vue")
  },
  {
    name: "tenant-subscriptions-renewal_exceptions-id",
    path: "/:tenant()/subscriptions/renewal_exceptions/:id()",
    meta: { ...(_91id_93heDcD8bWmpU6_4GsapQCfGfluhQ7spl38cf7RdubMLoMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/[id].vue")
  },
  {
    name: "tenant-subscriptions-renewal_exceptions-create",
    path: "/:tenant()/subscriptions/renewal_exceptions/create",
    meta: { ...(createXl33GZ8SOkob914L8HkTKSQeQzufwBB409W4FHIT2ikMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/create.vue")
  },
  {
    name: "tenant-subscriptions-renewal_exceptions",
    path: "/:tenant()/subscriptions/renewal_exceptions",
    meta: { ...(index2e9ZdDgsgMbeZjnFKVtrt6AwOqdCAv5tezehfoZkIwYMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/index.vue")
  },
  {
    name: "tenant-subscriptions-show-id",
    path: "/:tenant()/subscriptions/show/:id()",
    meta: { ...(_91id_93bNdg8G6TNIMLbZ56h_TYXLwblkK3QHuVfFSDIkp5ByQMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/show/[id].vue")
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/forget-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: { ...(indexTzqcTAs2QrZ_45CJUMyvFG8g2IdIj2YDlCeAK80F2MUCQMeta || {}), ...{"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/login.vue")
  },
  {
    name: "reset-password-token-email",
    path: "/reset-password/:token()/:email()",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/[email].vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/index.vue")
  },
  {
    name: "invoiceEdit",
    path: "/invoices/edit",
    meta: { ...(create_45editdu73qv_452gv4nSpZbdjsyl_7N2dt00qTcJSS9xBZW9CwMeta || {}), ...{"middleware":["sanctum:auth","nuxt-permissions"]} },
    component: () => import("~/pages/[tenant]/subscriptions/create-edit.vue")
  },
  {
    name: "invoiceCreate",
    path: "/invoices/create",
    meta: {"middleware":["sanctum:auth","nuxt-permissions"]},
    component: () => import("~/pages/[tenant]/subscriptions/create-edit.vue")
  }
]